import { differenceInSeconds, parseISO } from 'date-fns'

export const botEnvs = {
  prod: 'prod',
  stage: 'stage',
  dev: 'dev'
}

export const botStatuses = {
  active: 'active',
  inactive: 'inactive'
}

export const botPurposes = {
  stats: 'stats',
  buysender: 'buysender'
}

export const lastActiveIconColor = (lastActive) => {
  const colors = {
    success: 'text-success',
    warning: 'text-warning',
    error: 'text-danger'
  }

  if (!lastActive) return colors.error

  const successTime = 5
  const warningTime = 15
  const difference = differenceInSeconds(new Date(), parseISO(lastActive))

  switch (true) {
    case (difference <= successTime):
      return colors.success
    case (difference <= warningTime):
      return colors.warning
    default:
      return colors.error
  }
}
