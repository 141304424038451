<template>
  <vx-form
    v-slot="{ can, loading }"
    :resource="resource"
    @submit="handleSubmit"
  >
    <vx-select
      v-model="formValue.market_id"
      :rules="rules.market_id"
      :options="markets"
      :reduce="(content) => content.id"
      :disabled="isEdit"
      options-label="title"
      label="Select market"
      placeholder="Market"
      name="market_id"
    />
    <vx-input
      v-if="canChangeSecret"
      v-model="formValue.secret"
      :rules="rules.secret"
      label="Secret"
      name="secret"
    />
    <vx-json
      v-if="canChangeSecret"
      v-model="formValue.metadata"
      :rules="rules.metadata"
      :modes="['code']"
      mode="code"
      title="Metadata"
      show-copy
      name="metadata"
    />
    <vx-button
      :can="can"
      :loading="loading"
      :disabled="!canChangeSecret"
      type="filled"
      button="submit"
    >
      Save
    </vx-button>
    <b-alert v-if="!canChangeSecret" show variant="danger">
      You does not have needed permission to edit
    </b-alert>
  </vx-form>
</template>

<script>
import { permissions, useCan } from '@/plugins/acl'
import { computed, reactive } from '@vue/composition-api'
import { bots, passDataToResource } from '@/services/resources'
import { apiDefaultValidators, setValuesToForm } from '@/services/form'
import { VxForm, VxInput, VxJson, VxSelect } from '@/components/form'
import { VxButton } from '@/components/buttons'

export default {
  name: 'BotMarketEditor',
  components: {
    VxForm,
    VxJson,
    VxSelect,
    VxInput,
    VxButton
  },
  props: {
    botId: {
      type: Number,
      required: true
    },
    markets: {
      type: Array,
      required: true
    },
    marketBot: {
      type: Object,
      default: null
    }
  },
  setup ({ botId, marketBot }, { emit }) {
    const isEdit = !!marketBot

    const resource = isEdit
      ? passDataToResource(bots.updateMarket, {
        requestParams: {
          urlParams: { botId, marketId: marketBot.id }
        }
      })
      : passDataToResource(bots.createMarket, {
        requestParams: {
          urlParams: { id: botId }
        }
      })

    const formValue = reactive({
      market_id: null,
      secret: '',
      metadata: {}
    })

    const rules = {
      market_id: {
        required: true,
        numeric: true
      },
      secret: {
        required: true,
        max: apiDefaultValidators.maxTinyTextLength
      },
      metadata: {
        required: true,
        required_json: true
      }
    }

    const handleSubmit = ([err, res]) => {
      if (err) return
      emit('submit', res)
    }

    const canMarketSecretView = useCan(permissions.botMarketSecretView)
    const canChangeSecret = computed(() => {
      return isEdit ? canMarketSecretView : true
    })

    if (isEdit) {
      setValuesToForm(marketBot, formValue)
    }

    return {
      isEdit,

      formValue,
      resource,
      rules,
      handleSubmit,

      canMarketSecretView,
      canChangeSecret
    }
  }
}
</script>
