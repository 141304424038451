<template>
  <vx-card no-body>
    <template #title>
      Bot status
    </template>
    <b-card-body>
      <vx-form
        v-slot="{ can, loading }"
        :resource="formResource"
      >
        <vx-select
          v-model="formValue.status"
          :rules="rules.status"
          :options="statuses"
          :clearable="false"
          placeholder="Status"
          label="Status"
          name="status"
        />
        <vx-button
          variant="primary"
          type="submit"
          :can="can"
          :loading="loading"
        >
          Save
        </vx-button>
      </vx-form>
    </b-card-body>
  </vx-card>
</template>

<script>
import { botStatuses } from '@/views/markets/bot/botService'
import VxCard from '@/components/VxCard'
import { BCardBody } from 'bootstrap-vue'
import { VxForm, VxSelect } from '@/components/form'
import { VxButton } from '@/components/buttons'
import { reactive } from '@vue/composition-api'
import { bots, passDataToResource } from '@/services/resources'
import { setValuesToForm } from '@/services/form'

export default {
  name: 'BotStatusEditor',
  components: {
    BCardBody,
    VxButton,
    VxCard,
    VxForm,
    VxSelect
  },
  props: {
    bot: {
      type: Object,
      required: true
    }
  },
  setup ({ bot }) {
    const formValue = reactive({
      status: botStatuses.inactive
    })

    const formResource = passDataToResource(bots.updateStatus, {
      requestParams: { urlParams: { id: bot.id } }
    })

    const rules = {
      status: {
        required: true
      }
    }

    const statuses = Object.values(botStatuses)

    setValuesToForm(bot, formValue)

    return {
      formResource,
      formValue,
      rules,

      statuses
    }
  }
}
</script>
