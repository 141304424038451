<template>
  <div>
    <vx-table
      ref="tableRef"
      :resource="resource"
      :columns="columns"
      :prefetch="prefetch"
      :data-mapper="dataMapper"
    >
      <template #title>
        Markets
      </template>
      <template #actions>
        <vx-button
          variant="primary"
          :disabled="isAddButtonDisabled"
          :can="canCreateBotMarket"
          @click="openModal()"
        >
          Add
        </vx-button>
      </template>
    </vx-table>

    <b-modal
      v-model="modalIsOpen"
      :title="modalTitle"
      hide-footer
      title-tag="h4"
      @close="closeModal"
    >
      <bot-market-editor
        :bot-id="botId"
        :markets="allMarkets"
        :market-bot="entity"
        @submit="handleSubmit"
      />
    </b-modal>
  </div>
</template>

<script>
import { keyBy } from 'lodash'
import BotMarketEditor from '@/views/markets/bot/BotMarketEditor'
import { VxButton } from '@/components/buttons'
import { BModal } from 'bootstrap-vue'
import { VxCellButtons, VxCellMoney, VxTable } from '@/components/table'
import { bots, markets, passDataToResource, useResource } from '@/services/resources'
import { buttons } from '@/components/table/vx-table-cells/vx-cell-button'
import { computed, ref } from '@vue/composition-api'
import { useModalEditor } from '@/services/modal'

export default {
  name: 'BotMarkets',
  components: {
    BModal,
    VxButton,
    VxTable,
    BotMarketEditor
  },
  props: {
    botId: {
      type: Number,
      required: true
    }
  },
  setup ({ botId }) {
    const {
      can: canGetMarkets,
      callRequest: getMarketsRequest
    } = useResource(markets.getAll)

    const allMarkets = ref([])

    const { modalIsOpen, entity, openModal, closeModal } = useModalEditor()
    const modalTitle = computed(() => entity.value ? 'Update market' : 'Create market')

    const { can: canUpdateBotMarket } = useResource(bots.updateMarket)
    const { can: canCreateBotMarket } = useResource(bots.createMarket)

    const isAddButtonDisabled = computed(() => {
      return tableRef.value?.items.value?.length === allMarkets.value?.length
    })

    const tableRef = ref(null)

    const resource = passDataToResource(bots.getMarkets, {
      requestParams: {
        urlParams: { id: botId }
      }
    })

    const columns = [
      { key: 'id' },
      {
        key: 'market.title',
        label: 'Title'
      },
      {
        key: 'balance',
        component: VxCellMoney
      },
      {
        key: 'actions',
        component: VxCellButtons,
        tdAttr: (value, key, item) => ({
          buttons: actionButtons(item)
        })
      }
    ]

    const actionButtons = ({ id }) => {
      const editButton = {
        ...buttons.edit,
        can: canUpdateBotMarket,
        onClick: openModal
      }
      const deleteButton = {
        ...buttons.delete,
        resource: passDataToResource(bots.deleteMarket, {
          requestParams: { urlParams: { botId, marketId: id } }
        })
      }

      return [editButton, deleteButton]
    }

    const prefetch = async () => {
      if (!canGetMarkets) return

      const [err, res] = await getMarketsRequest({})
      if (err) return

      allMarkets.value = res.data
    }

    const dataMapper = (botMarkets) => {
      const marketsById = keyBy(allMarkets.value, 'id')
      return botMarkets.map((botMarket) => ({
        ...botMarket,
        market: marketsById[botMarket.market_id]
      }))
    }

    const handleSubmit = () => {
      tableRef.value.refresh()
      closeModal()
    }

    return {
      tableRef,
      resource,
      columns,
      prefetch,
      dataMapper,

      isAddButtonDisabled,
      canCreateBotMarket,

      allMarkets,

      modalIsOpen,
      entity,
      modalTitle,
      openModal,
      closeModal,
      handleSubmit
    }
  }
}
</script>
