<template>
  <b-row>
    <b-col cols="12" md="6">
      <bot-editor
        class="mb-2"
        :is-edit="isEdit"
        :bot="bot"
      />
      <bot-status-editor v-if="isEdit" :bot="bot" />
    </b-col>
    <b-col v-if="isEdit" cols="12" md="6">
      <bot-markets :bot-id="bot.id" class="mb-2" />
      <bot-steam-data-editor :bot-id="bot.id" />
    </b-col>
  </b-row>
</template>

<script>

import BotEditor from '@/views/markets/bot/BotEditor'
import BotMarkets from '@/views/markets/bot/BotMarkets'
import BotSteamDataEditor from '@/views/markets/bot/BotSteamDataEditor'
import BotStatusEditor from '@/views/markets/bot/BotStatusEditor'
import { BCol, BRow } from 'bootstrap-vue'

export default {
  name: 'Bot',
  components: {
    BRow,
    BCol,
    BotSteamDataEditor,
    BotMarkets,
    BotEditor,
    BotStatusEditor
  },
  props: {
    dataResolverResponse: {
      type: Object,
      default: null
    }
  },
  setup ({ dataResolverResponse }) {
    return {
      bot: dataResolverResponse,
      isEdit: !!dataResolverResponse
    }
  }
}
</script>
