<template>
  <vx-card>
    <vx-form
      v-slot="{ can, loading }"
      :resource="formResource"
      @submit="handleSubmit"
    >
      <vx-input
        v-model="formValue.name"
        :rules="rules.name"
        label="Name"
        name="name"
      />
      <vx-input
        v-model="formValue.auth_token"
        :rules="rules.auth_token"
        label="Auth token"
        name="auth_token"
      >
        <template #append>
          <vx-button
            v-b-tooltip="'Generate token'"
            variant="transparent"
            size="sm"
            icon="RefreshCwIcon"
            @click="generateAuthToken"
          />
        </template>
      </vx-input>
      <vx-select
        v-model="formValue.purpose"
        :rules="rules.purpose"
        :options="purposes"
        :clearable="false"
        label="Purpose"
        placeholder="Purpose"
        class="mb-2"
        name="purpose"
      />
      <vx-select
        v-model="formValue.env"
        :rules="rules.env"
        :options="envs"
        :clearable="false"
        label="Env"
        placeholder="Env"
        class="mb-2"
        name="env"
      />
      <b-row
        v-if="isEdit"
        class="mb-2"
      >
        <b-col cols="6">
          Last active
        </b-col>
        <b-col cols="6">
          <feather-icon icon="CircleIcon" class="mr-1" :class="lastActiveIconColor(bot.last_active_at)" />
          {{ dateFormat(bot.last_active_at) }}
        </b-col>
      </b-row>
      <vx-button
        variant="primary"
        type="submit"
        :loading="loading"
        :can="can"
      >
        Save
      </vx-button>
    </vx-form>
  </vx-card>
</template>

<script>
import { botEnvs, botPurposes, botStatuses, lastActiveIconColor } from '@/views/markets/bot/botService'
import voucherCodeGenerator from 'voucher-code-generator'
import VxCard from '@/components/VxCard'
import { BCardBody, BCol, BRow } from 'bootstrap-vue'
import { VxForm, VxInput, VxSelect } from '@/components/form'
import { VxButton } from '@/components/buttons'
import { dateFormat } from '@/services/utils'
import { reactive } from '@vue/composition-api'
import { apiDefaultValidators, setValuesToForm } from '@/services/form'
import { bots, passDataToResource } from '@/services/resources'
import router, { buildRoute, path } from '@/router'

export default {
  name: 'BotEditor',
  components: {
    BRow,
    BCol,
    BCardBody,
    VxButton,
    VxInput,
    VxSelect,
    VxCard,
    VxForm
  },
  props: {
    bot: {
      type: Object,
      default: () => ({})
    },
    isEdit: Boolean
  },
  setup ({ bot, isEdit }) {
    const frontToBackMapper = () => {
      return { ...formValue }
    }

    const formResource = isEdit
      ? passDataToResource(bots.update, {
        frontToBackMapper,
        requestParams: { urlParams: { id: bot.id } }
      })
      : passDataToResource(bots.create, {
        frontToBackMapper
      })

    const formValue = reactive({
      name: '',
      auth_token: '',
      env: botEnvs.dev,
      status: botStatuses.inactive,
      purpose: botPurposes.stats
    })

    const envs = Object.values(botEnvs)
    const purposes = Object.values(botPurposes)

    const { maxTinyTextLength } = apiDefaultValidators
    const rules = {
      name: {
        required: true,
        min: 2,
        max: maxTinyTextLength
      },
      auth_token: {
        required: true,
        min: 2,
        max: maxTinyTextLength
      },
      env: {
        required: true
      },
      purpose: {
        required: true
      }
    }

    if (isEdit) {
      setValuesToForm(bot, formValue)
    }

    const generateAuthToken = () => {
      const generatedCode = voucherCodeGenerator.generate({
        length: 15,
        count: 1,
        charset: voucherCodeGenerator.charset('alphanumeric')
      })
      formValue.auth_token = generatedCode[0]
    }

    const handleSubmit = ([err, res]) => {
      if (err) return

      if (!isEdit) {
        router.push(buildRoute(path.updateBot, {
          params: { id: res.data.id }
        }))
      }
    }

    return {
      formResource,
      formValue,
      rules,
      handleSubmit,

      envs,
      purposes,

      lastActiveIconColor,
      dateFormat,
      generateAuthToken
    }
  }
}
</script>
