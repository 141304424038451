<template>
  <vx-card
    :can="canGetSteamData"
    :loading="getSteamDataLoading"
    no-body
  >
    <template #title>
      Steam data
    </template>

    <b-card-body>
      <vx-form
        v-slot="{ can, loading }"
        :resource="formResource"
        @submit="handleSubmit"
      >
        <vx-input
          v-model="formValue.steam_id"
          :rules="rules.steam_id"
          label="Steam Id"
          type="number"
          name="steam_id"
        />
        <vx-input
          v-model="formValue.email"
          :rules="rules.email"
          label="Email"
          name="email"
        />
        <vx-input
          v-model="formValue.login"
          :rules="rules.login"
          label="Login"
          name="login"
        />
        <vx-input
          v-model="formValue.password"
          :rules="rules.password"
          label="Password"
          name="password"
        />
        <vx-input
          v-model="formValue.shared_secret"
          :rules="rules.shared_secret"
          label="Shared Secret"
          name="shared_secret"
        />
        <vx-input
          v-model="formValue.identity_secret"
          :rules="rules.identity_secret"
          label="Identity Secret"
          name="identity_secret"
        />
        <vx-input
          v-model="formValue.revocation_code"
          :rules="rules.revocation_code"
          label="Revocation Code"
          name="revocation_code"
        />
        <vx-input
          v-model="formValue.trade_token"
          :rules="rules.trade_token"
          label="Trade Token"
          name="trade_token"
        />
        <vx-button
          variant="primary"
          type="submit"
          :can="can"
          :loading="loading"
        >
          Save
        </vx-button>
      </vx-form>
    </b-card-body>
  </vx-card>
</template>

<script>
import VxCard from '@/components/VxCard'
import { BCardBody } from 'bootstrap-vue'
import { VxButton } from '@/components/buttons'
import { VxForm, VxInput } from '@/components/form'
import { computed, onBeforeMount, reactive, ref } from '@vue/composition-api'
import { apiDefaultValidators, setValuesToForm } from '@/services/form'
import { bots, passDataToResource, useResource } from '@/services/resources'

export default {
  name: 'BotSteamDataEditor',
  components: {
    BCardBody,
    VxButton,
    VxCard,
    VxForm,
    VxInput
  },
  props: {
    botId: {
      type: Number,
      required: true
    }
  },
  setup ({ botId }) {
    const {
      can: canGetSteamData,
      loading: getSteamDataLoading,
      callRequest: getSteamDataRequest
    } = useResource(bots.getSteamData)

    const steamData = ref(null)
    const isEdit = computed(() => !!steamData.value)

    const getSteamData = async () => {
      if (!canGetSteamData) return

      const [err, res] = await getSteamDataRequest({ urlParams: { id: botId } })
      if (err) return

      const data = res.data[0]
      if (!data) return

      steamData.value = res.data[0]

      setValuesToForm(steamData.value, formValue)
    }

    const frontToBackMapper = (data) => {
      return {
        ...data,
        steam_id: data.steam_id.toString()
      }
    }

    const formResource = computed(() => {
      return isEdit.value
        ? passDataToResource(bots.updateSteamData, {
          frontToBackMapper,
          requestParams: {
            urlParams: {
              botId,
              steamDataId: steamData.value.id
            }
          }
        })
        : passDataToResource(bots.createSteamData, {
          frontToBackMapper,
          requestParams: {
            urlParams: { id: botId }
          }
        })
    })

    const formValue = reactive({
      steam_id: '',
      email: '',
      login: '',
      password: '',
      shared_secret: '',
      identity_secret: '',
      revocation_code: '',
      trade_token: ''
    })

    const defaultRules = {
      required: true,
      max: apiDefaultValidators.maxTinyTextLength
    }

    const rules = {
      steam_id: defaultRules,
      email: {
        ...defaultRules,
        email: true
      },
      login: defaultRules,
      password: {
        ...defaultRules,
        min: 6
      },
      shared_secret: defaultRules,
      identity_secret: defaultRules,
      revocation_code: defaultRules,
      trade_token: defaultRules
    }

    const handleSubmit = ([err, res]) => {
      if (err) return

      if (!isEdit.value) {
        steamData.value = res.data
      }
    }

    onBeforeMount(() => {
      getSteamData()
    })

    return {
      canGetSteamData,
      getSteamDataLoading,

      formResource,
      formValue,
      rules,
      handleSubmit
    }
  }
}
</script>
